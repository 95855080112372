<template>
  <div class="contactus">
    <div class="main-limiter margin-bottom-4">
      <h1 class="margin-bottom-0">AI REV Platform Pre-sales AI</h1>
      <p class="text-center text-bold margin-top-0a">
        When preparing for sales meetings or deciding who to reach out to,
        reduce the usual 1h of research to minutes, gathering more accurate
        information on potential clients. Understand your prospects in a deeper,
        informed, and personal way with information about:
      </p>

      <div class="margin-bottom-4 reports-section">
        <div class="single-report">
          <DoroImage
            v-if="true"
            :src="ImageReportPage1"
            square
            simple
            loading="auto"
          ></DoroImage>
        </div>
        <div class="single-report">
          <DoroImage
            v-if="true"
            :src="ImageReportPage2"
            square
            simple
            loading="auto"
          ></DoroImage>
        </div>
      </div>
      <div class="text-center items-allign-center">
        <ul
          style="list-style-type: none; padding: 0; margin: 0; text-align: left"
        >
          <li class="custom-list-item">
            <img
              :src="SocialMedia"
              alt="Social Media Icon"
              class="icon-report"
            />
            Social Media activity and personal/company News coverage with
            relevant information.
          </li>
          <li class="custom-list-item2">
            <img :src="Summary" class="icon-report" />
            Summary of sales activity with prospects - actions from CRM and
            insights from meeting transcripts.
          </li>
          <li class="custom-list-item">
            <img :src="PersonalInformation" class="icon-report" />
            Personal information - hobbies, travels, awards, and other.
          </li>
          <li class="custom-list-item2">
            <img :src="CompanyInsights" class="icon-report" />
            Company insights - headcount growth, revenue.
          </li>
          <li class="custom-list-item">
            <img :src="PersonalityAssessment" class="icon-report" />
            Personality assessment based on prospect's Social Media activity and
            behavior in meetings.
          </li>
          <li class="custom-list-item2">
            <img :src="MostProbable" class="icon-report" />
            Most probable objections and the best approach and actions to
            anticipate them based on CRM data.
          </li>
        </ul>
      </div>
      <div class="margin-top-4">
        <h3 class="text-center margin-top-0a">
          We work with internal CRM, online, and social media data, to extract
          the most relevant information. AI REV Engine automatically infers and
          prepares a report , gathering more accurate information on potential
          clients. on selected prospective clients
        </h3>
      </div>
    </div>

    <div class="main-limiter margin-bottom-5">
      <el-row :gutter="20">
        <el-col :xs="24" :span="14">
          <h2>
            Schedule a 15 minute demo to learn more about AI REV’s Pre-sales AI
          </h2>
          <p>
            Choose your preferred way: <br />
            &bull; E-mail: contact@airev.us <br />
            <!--                        &bull; Call: +1 646-213-7667 <br>-->
            &bull; Schedule a Skype or Zoom call
            <strong> - Choose from available times in the calendar.</strong>
          </p>
        </el-col>
        <el-col :xs="24" :span="10">
          <iframe
            src="https://calendly.com/airevplatform"
            style="width: 100%; height: 600px; border: 0"
            sandbox="allow-same-origin allow-forms allow-scripts"
          ></iframe>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import ImageLogoAIRev from "@/assets/logos/airev-logo.svg";
import DoroImage from "@/components/DoroImage";

import reportPage1 from "@/assets/articles/platform/report-page1.svg";
import reportPage2 from "@/assets/articles/platform/report-page2.svg";

import socialMedia from "@/assets/articles/platform/social-media.svg";
import companyInsights from "@/assets/articles/platform/company-insights.svg";
import personalInformation from "@/assets/articles/platform/personal-information.svg";
import personalityAssessment from "@/assets/articles/platform/personality-assessment.svg";
import mostProbable from "@/assets/articles/platform/most-propable.svg";
import summary from "@/assets/articles/platform/summary.svg";

@Component({
  components: { DoroImage },
  metaInfo() {
    return {
      title: "Platform",
    };
  },
})
export default class PLatform extends Vue {
  get ImageLogoAIRev() {
    return ImageLogoAIRev;
  }

  get ImageReportPage1() {
    return reportPage1;
  }
  get ImageReportPage2() {
    return reportPage2;
  }

  get SocialMedia() {
    return socialMedia;
  }
  get CompanyInsights() {
    return companyInsights;
  }
  get PersonalityAssessment() {
    return personalityAssessment;
  }
  get PersonalInformation() {
    return personalInformation;
  }
  get MostProbable() {
    return mostProbable;
  }
  get Summary() {
    return summary;
  }
}
</script>
