<template>
    <div class="contactus">
        <div class="main-limiter margin-bottom-6">
            <h1 class="margin-bottom-0">Let's talk!</h1>
            <p class="text-center text-bold margin-top-0a">Would you like to talk about how AI can help you, your company, and your employers achieve more?<br/> Let's connect to discuss how we can help you!</p>
        </div>

        <div class="main-limiter margin-bottom-5">
            <el-row :gutter="20">
                <el-col :xs="24" :span="14">
                    <h2>Schedule a 15-min meeting with AI REV</h2>
                    <p>
                        Choose your preferred way: <br>
                        &bull; E-mail: contact@airev.us <br>
<!--                        &bull; Call: +1 646-213-7667 <br>-->
                        &bull; Schedule a Skype or Zoom call <strong> - Choose from available times in the calendar.</strong>
                    </p>
                </el-col>
                <el-col :xs="24" :span="10">
                    <iframe src="https://calendly.com/marek-bardonski/15min?hide_landing_page_details=1&hide_event_type_details=1" style="width:100%;height:600px;border:0;" sandbox="allow-same-origin allow-forms allow-scripts"></iframe>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';

    @Component({
        components: {
        },
        metaInfo() {
            return {
                title: 'Contact Us',
            }
        }
    })
    export default class ContactUs extends Vue {
    }
</script>
